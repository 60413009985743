<template>
  <div id="app">
    <div class="box">
      <div class="flex-item">
        <el-row :gutter="10">
          <el-col :span="6" v-for="(board, index) in hotBoards" :key="index">
            <hot-search-board
              :title="board.title"
              :icon="board.icon"
              :fetch-url="board.fetchUrl"
              :type="board.type"
            />
          </el-col>
        </el-row>
      </div>
      <div><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023014019号-1</a></div>
    </div>
    
  </div>
</template>

<script>
import HotSearchBoard from "@/components/HotSearchBoard.vue";
export default {
  name: "App",
  components: {
    HotSearchBoard,
  },
  data() {
    return {
      hotBoards: [
        {
          title: "百度",
          icon: require("@/assets/icons/baidu-icon.svg"),
          type: "baidu",
        },
        {
          title: "抖音",
          icon: require("@/assets/icons/douyin-icon.svg"),
          type: "douyin",
        },
        {
          title: "知乎",
          icon: require("@/assets/icons/zhihu-icon.svg"),
          type: "zhihu",
        },
        {
          title: "B站",
          icon: require("@/assets/icons/bilibili-icon.svg"),
          type: "bilibili",
        },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background: #f8f9fa; /* 提供一个柔和的背景色 */
  min-height: 100vh; /* 使用视口高度确保填满整个屏幕 */
  padding: 0; /* 保持整体布局紧凑，无额外内边距 */
}
.box {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.flex-item {
  flex-grow: 1;
}
</style>
